import React from "react";
import Container from "../../Layout/Container";
import { connect } from "react-redux";
import {
  getRivileBigBoxItems,
  getRivileBigBoxItem,
  getRivileBigBoxItemsFromImport,
} from "../../../redux/Actions/rivile/BigboxRivileAction";
import ContentBlock from "../../Layout/ContentBlock";
import ContentValidation from "../../common/ContentValidation";
import PaginationComp from "../../Dashboard/items/Pagination";
import TableColumnBigBox from "./items/TableColumnBigBox";
import styled from "styled-components";
import bigboxicon from "../../../../assets/images/bigboxicon.svg";
import RivileBigBoxEdit from "./items/RivileBigBoxEdit";
import {
  exportDocument,
  formatDatePlus,
  getDateFormat,
} from "../../../helpers/Utils";
import { Upload, message, Spin } from "antd";
import RangePickerComp from "../../common/RangePickerComp";
import InputText from "../../orders/accepted_orders/InputFilter";
import TableFilterBox from "../../common/TableFilterBox";
import TableUi from "../../common/TableUi";
import InputSwitchComponent from "../../common/InputSwitchComponent";
import { SelectForm } from "../../common/Buttons";

class Rivile extends React.Component {
  constructor() {
    super();
    this.state = {
      page: 1,
      pageCount: 100,
      editBlock: false,
      excel_loading: false,
      filterParams: "",
      filter: {
        code: "",
        number: "",
        date: ["", ""],
        order_type: "",
      },
    };
  }

  componentDidMount() {
    const paramsId = this.props.match.params.action;
    this.reloadData("");
    if (paramsId !== undefined) {
      this.onEdit(paramsId);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.page !== this.state.page ||
      prevState.pageCount !== this.state.pageCount
    ) {
      this.reloadData(this.state.filterParams);
    }
    const paramsId = this.props.match.params.action;
    if (prevProps.match.params.action !== paramsId) {
      this.onEdit(paramsId);
    }
  }

  reloadData = (data) => {
    this.props.getRivileBigBoxItems(
      this.state.page,
      this.state.pageCount,
      data
    );
  };

  onEdit = (id) => {
    if (id) {
      this.setState({
        editBlock: true,
      });
      this.props.getRivileBigBoxItem(id);
      this.props.history.push(`/rivile/bigbox/${id}`);
    } else {
      this.setState({
        editBlock: false,
      });
      this.props.history.push(`/rivile/bigbox/`);
    }
  };

  setPagination = (page) => {
    this.setState({
      page,
    });
  };

  setPageCount = (pageCount) => {
    this.setState({
      pageCount,
      page: 1,
    });
  };

  makeRequest = () => {
    window.alert("Request");
  };

  exportDocumentFunc = (id, name, type = "excel_old_download") => {
    exportDocument(`/api/v1/rivile_requests/${type}/${id}`, `${name}.xlsx`);
  };

  renderImportButton = () => {
    const props = {
      name: "file",
      action: `/api/v1/rivile_requests_documents`,
      showUploadList: false,
      beforeUpload: this.beforeUpload,
      onChange: this.onChange,
    };

    return (
      <>
        <Upload {...props} className="float-right mr-3">
          <StyledButton
            className="btn btn-sm btn-primary"
            disabled={this.state.excel_loading}
          >
            {this.state.excel_loading ? (
              <Spin size="small" />
            ) : (
              "Įkelti užklausą"
            )}
          </StyledButton>
        </Upload>
      </>
    );
  };

  beforeUpload = (file) => {
    this.setState({
      excel_loading: true,
    });
    return true;
  };

  onChange = (info) => {
    if (info.file.status === "done") {
      message.success(`${info.file.name} failas sėkmingai įkeltas!`);
      this.setState({
        excel_loading: false,
      });
      if (info.file) {
        this.props.getRivileBigBoxItemsFromImport(info.file.response);
      }
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} failo įkėlimas nepavyko!`);
      this.setState({
        excel_loading: false,
      });
    }
  };

  // Filter functions

  delayOnFilter = () => {
    if (this.props.loading) {
      return setTimeout(() => {
        return this.delayOnFilter();
      }, 1000);
    }
    this.onFilter();
  };

  filterChange = (e, date) => {
    if (date) {
      this.setState(
        {
          filter: {
            ...this.state.filter,
            [date]: e,
          },
        },
        () => this.onFilter()
      );
    } else {
      const { name, value } = e.target;
      this.setState(
        {
          filter: {
            ...this.state.filter,
            [name]: value,
          },
        },
        () => this.onFilter()
      );
    }
  };

  onFilter = () => {
    if (this.props.loading) {
      return this.delayOnFilter();
    }
    let filterParams = [];

    if (this.state.filter.number.length > 0) {
      filterParams.push(
        `&q[rivile_requests_order_number_or_rivile_requests_product_name_or_rivile_requests_bb_code_or_rivile_requests_rivile_code_cont_any]=${this.state.filter.number}`
      );
    }

    if (this.state.filter.date[0].length > 0)
      filterParams.push(`&q[created_at_gteq]=${this.state.filter.date[0]}`);

    if (this.state.filter.date[1].length > 0) {
      filterParams.push(
        `&q[created_at_lt]=${formatDatePlus(this.state.filter.date[1])}`
      );
    }

    if (this.state.filter.order_type) {
      if (this.state.filter.order_type === "true") {
        filterParams.push(`&only_withdrawal=true`);
      } else {
        filterParams.push(`&only_dispatch=true`);
      }
    }

    if (this.state.filter.status) {
      if (this.state.filter.status === "received") {
        filterParams.push(`&q[call_back_at_null]=0`);
      } else {
        if (this.state.filter.status === "exported")
          filterParams.push(`&q[exported_at_null]=0&q[call_back_at_null]=1`);
        else filterParams.push(`&q[exported_at_null]=1`);
      }
    }

    this.setState(
      {
        paramsFilter: filterParams.join(""),
        page: 1,
      },
      () => this.reloadData(filterParams.join(""))
    );
  };

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.onFilter();
    }
  };

  clearFilter = () => {
    this.setState(
      {
        filter: {
          ...this.state.filter,
          code: "",
          date: ["", ""],
          number: "",
          order_type: "",
          status: "",
        },
      },
      () => this.onFilter()
    );
  };

  render() {
    const { editBlock } = this.state;
    return (
      <Container
        location={{
          locationName: "Rivilė BigBox",
          locationUrl: this.props.match.url,
        }}
      >
        {editBlock ? (
          <RivileBigBoxEdit
            bigbox={this.props.bigbox}
            loading_show={this.props.loading_show}
            StyledButton={StyledButton}
            StyledButton2={StyledButton2}
            StyledButton3={StyledButton3}
            onEdit={this.onEdit}
            show_id={this.props.show_id}
            exportDocumentFunc={this.exportDocumentFunc}
          />
        ) : (
          <>
            <ContentBlock>
              <div className="mx-3 my-1 d-flex align-items-center justify-content-between">
                <div>
                  <img
                    src={bigboxicon}
                    className="mr-2"
                    style={{ width: "20px", height: "20px" }}
                  />
                  <span
                    className="filterMenuText first"
                    style={{ color: "#A2A2A2" }}
                  >
                    Viso įkelta užklausų
                  </span>
                  <span className="font-weight-bold ml-2">
                    {this.props.bigboxs.length}/{this.props.totals}
                  </span>
                </div>
              </div>
            </ContentBlock>
            <ContentBlock>
              <TableFilterBox
                clearFilter={() => this.clearFilter()}
                button1={this.renderImportButton()}
              >
                <div className="col-3 px-1">
                  <InputSwitchComponent
                    label="Tipas"
                    name="product_qty_in_order"
                    options={[
                      {
                        label: "Visi",
                        value: "",
                      },
                      {
                        label: "Atsiėmimas",
                        value: "true",
                      },
                      {
                        label: "Išsiuntimas",
                        value: "false",
                      },
                    ]}
                    value={this.state.filter.order_type || ""}
                    onChange={(val) => {
                      this.filterChange({
                        target: {
                          name: "order_type",
                          value: val.target.value,
                        },
                      });
                    }}
                  />
                </div>
                <div className="px-1">
                  <InputText
                    style={{ width: 150, fontSize: "0.75rem" }}
                    number="1"
                    name="number"
                    placeholder="Kodas/Pavadinimassss"
                    onKeyDown={this.handleKeyDown}
                    value={this.state.filter.number}
                    onChange={(e) => this.filterChange(e)}
                  />
                </div>
                <div className="px-1">
                  <div className="form-group">
                    <label className="editLabelForm">Data</label>
                    <div>
                      <RangePickerComp
                        style={{ width: 250 }}
                        placeholder={["Pradžios laikas", "Pabaigos laikas"]}
                        value={[
                          getDateFormat(this.state.filter.date[0]),
                          getDateFormat(this.state.filter.date[1]),
                        ]}
                        onChange={(e, args) => this.filterChange(args, "date")}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-2 px-1">
                  <SelectForm
                    options={[
                      {
                        value: "",
                        label: "Visi",
                      },
                      {
                        value: "exported",
                        label: "Eksportuota",
                      },
                      {
                        value: "not_exported",
                        label: "Ne eksportuota",
                      },
                      {
                        value: "received",
                        label: "Gauta",
                      },
                    ]}
                    placeholder="Statusas"
                    value={this.state.filter.status || ""}
                    dropdownMatchSelectWidth={false}
                    onChange={(status) => {
                      this.filterChange({
                        target: {
                          name: "status",
                          value: status,
                        },
                      });
                    }}
                  />
                </div>
              </TableFilterBox>
              <div>
                <ContentValidation
                  loader={this.props.loading}
                  array={this.props.bigboxs}
                  alertId_s="rivileAlert-s"
                  alertId_e="rivileAlert-e"
                >
                  <div className="table-responsive">
                    <TableUi
                      ths={[
                        "Tipas ir data",
                        "Nr.",
                        "Eksportuota į BB",
                        "Istorija",
                        "",
                      ]}
                    >
                      {this.props.bigboxs.map((item, index) => {
                        return (
                          <TableColumnBigBox
                            key={index}
                            onEdit={this.onEdit}
                            item={item}
                            orders={item?.orders}
                            exportDocumentFunc={this.exportDocumentFunc}
                            seen={item.seens.includes(this.props.user_id)}
                          />
                        );
                      })}
                    </TableUi>
                    <PaginationComp
                      setPagination={this.setPagination}
                      page={this.state.page}
                      pageCount={this.state.pageCount}
                      totals={this.props.totals}
                      changeStatusAction={this.props.changeStatusAction}
                    />
                  </div>
                </ContentValidation>
              </div>
            </ContentBlock>
          </>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  bigboxs: state.bigbox.bigboxs,
  bigbox: state.bigbox.bigbox,
  loading: state.bigbox.loading,
  loading_show: state.bigbox.loading_show,
  show_id: state.bigbox.show_id,
  totals: state.bigbox.totals,
  user_id: state.auth.user.id,
});

export default connect(mapStateToProps, {
  getRivileBigBoxItems,
  getRivileBigBoxItem,
  getRivileBigBoxItemsFromImport,
})(Rivile);

const ThStyled = styled.th`
  font-size: 13px;
  color: #000;
`;

const StyledButton = styled.button`
  width: 112px;
  background-color: rgb(242, 147, 2);
  border-radius: 3px;
  font-size: 13px;
  color: #fff;
  font-weight: 300;
  border-width: 0;

  &:hover {
    background-color: #fff;
    color: rgb(242, 147, 2);
  }
  &:active,
  &:focus {
    background-color: rgb(242, 147, 2);
    color: #fff;
  }
`;

const StyledButton2 = styled.button`
  width: 112px;
  background-color: #17a2b8;
  border-radius: 3px;
  font-size: 13px;
  color: #fff;
  font-weight: 300;
  border-width: 0;
  margin-left: 10px;

  &:hover {
    background-color: #fff;
    color: #17a2b8;
  }
  &:active,
  &:focus {
    background-color: #17a2b8;
    color: #fff;
  }
`;

const StyledButton3 = styled.button`
  width: 112px;
  background-color: ${(props) => props.color || "#ef3824"};
  border-radius: 3px;
  font-size: 13px;
  color: #fff;
  font-weight: 300;
  border-width: 0;
  margin-left: 10px;

  &:hover {
    background-color: #fff;
    color: ${(props) => props.color || "#ef3824"};
  }
  &:active,
  &:focus {
    background-color: ${(props) => props.color || "#ef3824"};
    color: #fff;
  }
`;
