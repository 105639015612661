import React from "react";
import moment from "moment";
import { Tooltip } from "antd";
import { BsFillEyeFill } from "react-icons/bs";
import { ImFileEmpty } from "react-icons/im";
import { prettyDateFormat } from "../../../../helpers/Utils";
import { useState } from "react";
import { RoundedBubble } from "../../../common/StyledComponents";
import { useDispatch } from "react-redux";
import {
  IconTrRequestDispatch,
  IconTrRequestWithdrawal,
} from "../../../Layout/Icons";
import RegularOrderChildren from "../../../common/GlobalModals/Orders/RegularOrderChildren";

function TableColumnRivile({ item, onEdit, exportDocumentFunc, seen, orders }) {
  const date = moment(item.created_at).format("YYYY-MM-DD");

  const areAllPickupsTrue = (orders) => {
    if (!orders || orders.length === 0) return false;
    return !orders.some((order) => !order.pickup);
  };

  const allPickupsTrue = areAllPickupsTrue(orders);
  return (
    <tr className="align-middle tableColumn">
      <td className="align-middle">
        <div style={{ height: 25 }} className="d-flex align-items-center">
          {orders && orders.length > 0 ? (
            allPickupsTrue ? (
              <Tooltip placement="top" title="Atsiėmimas">
                <div className="d-flex align-items-center justify-content-center">
                  <IconTrRequestWithdrawal />
                </div>
              </Tooltip>
            ) : (
              <Tooltip placement="top" title="Išsiuntimas">
                <div className="d-flex align-items-center justify-content-center">
                  <IconTrRequestDispatch />
                </div>
              </Tooltip>
            )
          ) : (
            ""
          )}

          <p className="tr-request-date-text">
            {prettyDateFormat(item.created_at)}
          </p>
        </div>
      </td>
      <td className="align-middle pl-3" style={{ fontSize: 13 }}>
        {orders?.length > 0 ? <OrderPreview orders={orders || []} /> : "-"}
      </td>
      <td className="align-middle">
        <div
          style={{
            width: 20,
          }}
        >
          {item.bb_number && (
            <div className="d-flex align-items-center">
              <div
                className="mr-2"
                style={{
                  border: !seen ? "1px solid #F29302" : "none",
                  marginRight: 5,
                  borderRadius: "50%",
                  padding: 2,
                }}
              >
                <div
                  style={{
                    background: item.exported_at ? "#5CB800" : "#DC0032",
                    width: !seen ? 8 : 10,
                    height: !seen ? 8 : 10,
                    borderRadius: "50%",
                  }}
                />
              </div>
              {item.exported_at
                ? `Eksportuotas (${seen ? "Perskaitytas" : "Neperskaitytas"})`
                : "Neeksportuotas"}
            </div>
          )}
        </div>
      </td>
      <td className="align-middle">
        <HistoryPreview
          rivile_requests_document_histories={
            item?.rivile_requests_document_histories || []
          }
        />
      </td>
      <td className="align-middle pr-3">
        <div className="d-flex justify-content-end">
          {item.finished_at ? (
            <button
              className="btn btn-sm p-0"
              style={{ background: "#f29302", width: 29, height: 29 }}
              onClick={() => onEdit(item.id)}
            >
              <BsFillEyeFill className="text-white" size={14} />
            </button>
          ) : (
            <div className="text-danger">Sinchronizuojama</div>
          )}
          <Tooltip
            placement="left"
            title={
              <div>
                Eksportuoti dokumentą kaip{" "}
                <span
                  className="text-orange cursor-pointer white-hover font-semi-bold ml-1"
                  onClick={() =>
                    exportDocumentFunc(
                      item.id,
                      item.user_name + "_" + date,
                      "excel_download"
                    )
                  }
                >
                  naują
                </span>{" "}
                /{" "}
                <span
                  className="text-orange cursor-pointer white-hover font-semi-bold ml-1"
                  onClick={() =>
                    exportDocumentFunc(item.id, item.user_name + "_" + date)
                  }
                >
                  seną
                </span>{" "}
                ?
              </div>
            }
            trigger="click"
          >
            <button
              className="btn btn-sm ml-3 p-0"
              style={{ background: "#EF3824", width: 29, height: 29 }}
            >
              <ImFileEmpty className="text-white" size={14} />
            </button>{" "}
          </Tooltip>
        </div>
      </td>
    </tr>
  );
}

export default TableColumnRivile;

const HistoryPreview = ({ rivile_requests_document_histories }) => {
  const [showAll, setShowAll] = useState(false);
  const total = rivile_requests_document_histories.length;
  return (
    <div
      className="bg-white"
      style={{
        border: "1px solid #EBEBEB",
        borderRadius: 4,
      }}
    >
      <div
        className="col-12 d-flex align-items-center justify-content-between py-2 font-medium"
        style={{
          borderBottom: "1px solid #EBEBEB",
        }}
      >
        <div className="d-flex align-items-center">
          <RoundedBubble>{total}</RoundedBubble>
          <div className="text-sm text-black">įvykiai</div>
        </div>
        <div
          className="text-sm text-gray-500 text-decoration-underline cursor-pointer"
          onClick={() => setShowAll((prev) => !prev)}
        >
          {showAll ? "Paslėpti visus" : "Rodyti visus"}
        </div>
      </div>
      {showAll &&
        rivile_requests_document_histories.map((d, idx) => {
          return (
            <div className="text-sm col-12" key={d.id}>
              <div
                className="d-flex align-items-center py-2"
                style={{
                  borderBottom:
                    total == idx + 1 ? "none" : "1px solid rgba(0, 0, 0, 0.08)",
                }}
              >
                <RoundedBubble primary={false}>{idx + 1}</RoundedBubble>
                <div>
                  <b>
                    {!!d.value && `[${d.value}] `}
                    {status_names[d.action] || "Be statuso"}:{" "}
                    {prettyDateFormat(d.created_at)}
                  </b>
                  <div
                    style={{
                      lineHeight: "17px",
                    }}
                  >
                    {d.info.full_name}; {d.info.email}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

const status_names = {
  Export: "Eksportuotas į BB",
  Update: "Atnaujintas iš BB",
  Create: "Sukurtas",
  create_bb: "Sukurtas iš BB",
  email_send: "Email išsiuntimas",
};

const OrderPreview = ({ orders }) => {
  const dispatch = useDispatch();
  const [showAll, setShowAll] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const total = orders?.length;
  const modalRef = React.createRef();

  const orderModal = (order_id, id) => {
    const type = order_id.startsWith("FO") ? "FakeOrder" : "Order";
    if (type === "FakeOrder") {
      modalRef.current.openModal("FakeOrderShowModal");
      return;
    }
    setOrderId(id);
    dispatch(getOrder(id));
    modalRef.current.openModal("OrderShowModal");
  };

  const renderOrderNumbers = () =>
    orders?.map((d) => (
      <div className="text-sm pr-1" key={d.number}>
        <p
          style={{
            margin: 0,
          }}
        >
          {d.number}
        </p>
      </div>
    ));

  if (total < 5) {
    return (
      <div className="d-flex flex-row flex-wrap">
        {renderOrderNumbers()}
        <div id="modal-roots" />
        <RegularOrderChildren
          ref={modalRef}
          requiredOrderId={orderId}
          modal_type={"TrRequest"}
        />
      </div>
    );
  }

  return (
    <div
      className="bg-white"
      style={{
        border: "1px solid #EBEBEB",
        borderRadius: 4,
        maxWidth: 300,
      }}
    >
      <div
        className="col-12 d-flex align-items-center justify-content-between py-2 font-medium"
        style={{
          borderBottom: "1px solid #EBEBEB",
        }}
      >
        <div className="d-flex align-items-center">
          <RoundedBubble>{total}</RoundedBubble>
          <div className="text-sm text-black">orders</div>
        </div>
        <div
          className="text-sm text-gray-500 text-decoration-underline cursor-pointer"
          onClick={() => setShowAll((prev) => !prev)}
        >
          {showAll ? "Hide all" : "Show all"}
        </div>
      </div>
      <div style={{ maxWidth: 300 }} className="d-flex flex-row flex-wrap px-3">
        {showAll && renderOrderNumbers()}
      </div>
      <div id="modal-roots" />
      <RegularOrderChildren ref={modalRef} requiredOrderId={orderId} />
    </div>
  );
};
